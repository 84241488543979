<template>
  <div class="card card-custom">
    <div class="card-body">
      <InputFormGroup
          :label="$t('label.name')"
          :placeholder="$t('placeholder.enter_name')"
          :value="form.name"
          required
          :with-error="error.name"
          @input="form.name = $event.trim()"
      />
      <InputFormGroup
          :label="$t('label.last_name')"
          :placeholder="$t('placeholder.enter_last_name')"
          :value="form.last_name"
          required
          :with-error="error.last_name"
          @input="form.last_name = $event.trim()"
      />
      <InputFormGroup
          :label="$t('label.second_name')"
          :placeholder="$t('placeholder.enter_second_name')"
          :value="form.second_name"
          @input="form.second_name = $event.trim()"
      />
<!--      <MultiselectFormGroup-->
<!--          class="app-view__form-group"-->
<!--          :label="$t('label.country')"-->
<!--          :placeholder="$t('placeholder.choose_country')"-->
<!--          :value="computedCountriesList.find(el => el.id === form.country)"-->
<!--          :options="computedCountriesList"-->
<!--          required-->
<!--          :with-error="error.country"-->
<!--          @select="form.country = $event.id"-->
<!--          @remove="form.country = null"-->
<!--      />-->
      <InputFormGroup
          :label="$t('label.country')"
          :placeholder="$t('placeholder.enter_country')"
          :value="form.country"
          required
          :with-error="error.country"
          @input="form.country = $event.trim()"
      />
      <InputFormGroup
          :label="$t('label.city')"
          :placeholder="$t('placeholder.enter_city')"
          :value="form.city"
          required
          :with-error="error.city"
          @input="form.city = $event.trim()"
      />
      <InputFormGroup
          v-for="(_, idx) in phoneNumbers"
          :key="idx"
          :label="idx === 0 ? $t('label.phone_number') : null"
          :placeholder="$t('placeholder.enter_phone_number')"
          :value="phoneNumbers[idx]"
          required
          :with-error="error.phone"
          @input="phoneNumbers[idx] = $event"
      />
      <div class="d-flex justify-content-center w-100 mt-n5">
        <button type="button" class="btn btn-sm btn-light-info" @click="phoneNumbers.push('')">
          <span class="far fa-plus"></span>
        </button>
      </div>
      <InputFormGroup
          :label="$t('label.email')"
          :placeholder="$t('placeholder.enter_email')"
          :value="form.email"
          required
          :with-error="error.email"
          @input="form.email = $event.trim()"
      />
      <InputFormGroup
          :label="$t('label.messenger')"
          :placeholder="$t('placeholder.enter_messenger')"
          :value="form.messenger"
          required
          :with-error="error.messenger"
          @input="form.messenger = $event.trim()"
      />
    </div>
    <div class="card-footer d-flex justify-content-end">
      <button type="button" class="btn btn-light mr-3" @click="$router.push({name: 'loyalty'})">
        {{ $t('btn.back_to_loyalty') }}
      </button>
      <button type="button" class="btn btn-success" @click="submitForm">{{ $t('btn.submit') }}</button>
    </div>
  </div>
</template>

<script>
import InputFormGroup from '@/components/elements/form-groups/InputFormGroup';
import MultiselectFormGroup from '@/components/elements/form-groups/MultiselectFormGroup';
import {mapGetters} from 'vuex';

export default {
  components: {
    InputFormGroup,
    MultiselectFormGroup
  },
  data: () => ({
    phoneNumbers: [''],
    form: {
      city: null,
      country: null,
      email: null,
      last_name: null,
      messenger: null,
      name: null,
      phone: [],
      second_name: null,
    },
    error: {
      city: false,
      country: false,
      email: false,
      last_name: false,
      messenger: false,
      name: false,
      phone: false,
    }
  }),
  methods: {
    async submitForm() {
      this.form.phone = this.phoneNumbers.filter(el => el !== '');
      if (!this.validateForm()) return false;
      await this.$store.dispatch('loyaltyStore/CREATE_RECOMMENDATION', this.form);
      this.$router.push({name: 'loyalty'});
    },
    validateForm() {
      this.error.city = !this.form.city;
      this.error.country = !this.form.country;
      this.error.email = !this.form.email;
      this.error.last_name = !this.form.last_name;
      this.error.messenger = !this.form.messenger;
      this.error.name = !this.form.name;
      this.error.phone = !this.form.phone.filter(el => el !== '').length;

      return !Object.values(this.error).includes(true);
    }
  }
}
</script>